import { PumpDataState } from "../../types/pumpData";
import {
  CLEAR_SAVE_PUMP_DATA_ERROR,
  CLEAR_TEST_SELECTION_COMBINATION,
  CLOSE_TEST_SELECTION_MODAL,
  CLOSE_PRINT_SELECTION_MODAL,
  OPEN_PRINT_SELECTION_MODAL,
  GET_PUMP_DETAILS_PENDING,
  GET_PUMP_DETAILS_SUCCESS,
  OPEN_TEST_SELECTION_MODAL,
  SAVE_PUMP_DATA_ERROR,
  SAVE_PUMP_DATA_PENDING,
  SAVE_PUMP_DATA_SUCCESS,
  SET_GENERAL_DATA,
  SET_OUTPUT_TEST_DATA,
  SET_OUTPUT_NPSH_DATA,
  SET_INITIAL_STATE,
  SET_PRINT_INITIAL_STATE,
  SET_PUMP_DATA_LIST,
  SET_PUMP_DATA_LIST_PENDING,
  SET_TEST_SELECTION_COMBINATION,
  SET_TEST_DATA,
  SET_NPSH_DATA,
  OPEN_HI2_SELECTION_MODAL,
  CLOSE_HI2_SELECTION_MODAL,
  SET_PUMP_FAMILY_LIST,
  SET_PUMP_SUBTYPE_LIST,
  SET_PUMP_SUBTYPE_DATA,
  GET_PUMP_FAMILY_LIST_PENDING,
  GET_PUMP_FAMILY_LIST_SUCCESS,
  GET_PUMP_SUBTYPE_PENDING,
  GET_PUMP_SUBTYPE_SUCCESS,
  GET_PUMP_SUBTYPE_DATA_PENDING,
  GET_PUMP_SUBTYPE_DATA_SUCCESS,
  SET_GRAPH_DATA,
  GET_GRAPH_DATA_PENDING,
  GET_GRAPH_DATA_SUCCESS,
  SET_FILTERED_PUMP_DATA_IDS,
  GET_NPSH_DATA_SUCCESS,
  GET_NPSH_DATA_PENDING,
  GET_TEST_DATA_SUCCESS,
  GET_TEST_DATA_PENDING,
  CLEAR_ACTIVE_PUMP_HI2_SELECTION,
  SET_ACTIVE_PUMP_SUBTYPE_DATA,
  SET_ACTIVE_PUMP_SUBTYPE,
  SET_ACTIVE_PUMP_FAMILY,
  SET_PUMP_DATA_LIST_FILTER,
  CLEAR_PUMP_DATA_LIST_FILTER,
  DEFAULT_SEARCH_DATA,
  SET_SELECTED_PUMP_DATA_DETAILS,
  CLEAR_SELECTED_PUMP_DATA_DETAILS,
  SET_EXPORT_INITIAL_STATE,
  OPEN_EXPORT_SELECTION_MODAL,
  CLOSE_EXPORT_SELECTION_MODAL,
  SET_PUMP_DATA_LIST_PAGER,
  CLEAR_PUMP_DETAILS,
  ADD_FILTERED_PUMP_DATA_IDS,
  RESET_CAN_OPEN_PUMP_DETAILS_FLAG,
  SET_PUMP_DATA_COPIED_FLAG,
  RESET_PUMP_DATA_COPIED_FLAG,
  SET_2ND_TEST_CURVE,
  SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  SET_MODIFIED_DATA,
} from "../constants/pumpData.constants";

const initialState: PumpDataState = {
  isTestSelectionModalOpen: false,
  isPrintSelectionModalOpen: false,
  isPending: false,
  isDataModified: false,
  pumpDataList: [],
  selectedTestCombination: {},
  generalData: {},
  inputTestData: [],
  outputTestData: [],
  inputNPSHData: [],
  outputNPSHData: [],
  isGetPumpDetailsPending: false,
  isSavePumpDataPending: false,
  errorMessage: null,
  isHI2SelectionModalOpen: false,
  pumpFamilyList: [],
  pumpSubTypeList: [],
  pumpSubTypeData: [],
  isGetPumpSubTypeDataPending: false,
  isGetPumpSubTypePending: false,
  isGetPumpFamilyPending: false,
  graphData: {},
  isGetGraphPending: false,
  filteredPumpDataIds: [],
  isGetTestDataPending: false,
  isGetNPSHDataPending: false,
  filter: {
    selected_approved_filter: {
      label: "All",
      value: undefined,
    },
    selected_basic_curve_filter: {
      label: "All",
      value: undefined,
    },
    selected_sorting: {
      label: "Test No: High to Low",
      value: "TestNo#desc",
    },
    selected_type: {
      label: "All",
      value: undefined,
    },
    searchData: DEFAULT_SEARCH_DATA,
    from: "",
    to: "",
  },
  selectedPumpDataScrollPosition: 0,
  pager: {},
  selectedPumpDataRowId: undefined,
};

export default (
  localState: UserState = initialState,
  action: Action
): PumpDataState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isTestSelectionModalOpen: true,
      };
    }
    case OPEN_TEST_SELECTION_MODAL: {
      return {
        ...localState,
        isTestSelectionModalOpen: true,
      };
    }
    case CLOSE_TEST_SELECTION_MODAL: {
      return {
        ...localState,
        isTestSelectionModalOpen: false,
      };
    }
    case SET_PRINT_INITIAL_STATE: {
      return {
        ...initialState,
        isPrintSelectionModalOpen: false,
      };
    }
    case OPEN_PRINT_SELECTION_MODAL: {
      return {
        ...localState,
        isPrintSelectionModalOpen: true,
      };
    }
    case CLOSE_PRINT_SELECTION_MODAL: {
      return {
        ...localState,
        isPrintSelectionModalOpen: false,
      };
    }
    case SET_PUMP_DATA_LIST_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case SET_PUMP_DATA_LIST: {
      const { pumpListOdata } = action.payload;
      return {
        ...localState,
        isPending: false,
        pumpDataList: pumpListOdata && pumpListOdata.value,
        totalRecords: pumpListOdata["@odata.count"],
      };
    }
    case SET_TEST_SELECTION_COMBINATION: {
      const { selectedTest } = action.payload;
      return {
        ...localState,
        selectedTestCombination: selectedTest,
      };
    }
    case CLEAR_TEST_SELECTION_COMBINATION: {
      return {
        ...localState,
        selectedTestCombination: {},
      };
    }
    case SET_GENERAL_DATA: {
      return {
        ...localState,
        generalData: action.payload ?? {},
      };
    }
    case SET_MODIFIED_DATA: {
      return {
        ...localState,
        isDataModified: true,
      };
    }
    case GET_PUMP_DETAILS_PENDING: {
      return {
        ...localState,
        isGetPumpDetailsPending: true,
      };
    }
    case GET_PUMP_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetPumpDetailsPending: false,
      };
    }
    case SAVE_PUMP_DATA_SUCCESS: {
      return {
        ...localState,
        isSavePumpDataPending: false,
        isDataModified: false,
      };
    }
    case SAVE_PUMP_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isSavePumpDataPending: false,
        isDataModified: false,
      };
    }
    case SAVE_PUMP_DATA_PENDING: {
      return {
        ...localState,
        isSavePumpDataPending: true,
      };
    }
    case CLEAR_SAVE_PUMP_DATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_TEST_DATA: {
      const { inputTestData, outputTestData } = action.payload;
      return {
        ...localState,
        inputTestData: inputTestData ?? [],
        outputTestData: outputTestData ?? [],
      };
    }
    case SET_OUTPUT_TEST_DATA: {
      const { outputTestData } = action.payload;
      return {
        ...localState,
        outputTestData: outputTestData,
      };
    }
    case SET_NPSH_DATA: {
      const { inputNPSHData, outputNPSHData } = action.payload;
      return {
        ...localState,
        inputNPSHData: inputNPSHData ?? [],
        outputNPSHData: outputNPSHData ?? [],
      };
    }
    case SET_OUTPUT_NPSH_DATA: {
      const { outputNPSHData } = action.payload;
      return {
        ...localState,
        outputNPSHData: outputNPSHData,
      };
    }
    case OPEN_HI2_SELECTION_MODAL: {
      return {
        ...localState,
        isHI2SelectionModalOpen: true,
      };
    }
    case CLOSE_HI2_SELECTION_MODAL: {
      return {
        ...localState,
        isHI2SelectionModalOpen: false,
      };
    }
    case SET_PUMP_FAMILY_LIST: {
      return {
        ...localState,
        pumpFamilyList: action.payload ?? [],
      };
    }
    case SET_PUMP_SUBTYPE_LIST: {
      return {
        ...localState,
        pumpSubTypeList: action.payload ?? [],
      };
    }
    case SET_PUMP_SUBTYPE_DATA: {
      return {
        ...localState,
        pumpSubTypeData: action.payload ?? [],
      };
    }
    case GET_PUMP_FAMILY_LIST_PENDING: {
      return {
        ...localState,
        isGetPumpFamilyPending: true,
      };
    }
    case GET_PUMP_FAMILY_LIST_SUCCESS: {
      return {
        ...localState,
        isGetPumpFamilyPending: false,
      };
    }
    case GET_PUMP_SUBTYPE_PENDING: {
      return {
        ...localState,
        isGetPumpSubTypePending: true,
      };
    }
    case GET_PUMP_SUBTYPE_SUCCESS: {
      return {
        ...localState,
        isGetPumpSubTypePending: false,
      };
    }
    case GET_PUMP_SUBTYPE_DATA_PENDING: {
      return {
        ...localState,
        isGetPumpSubTypeDataPending: true,
      };
    }
    case GET_PUMP_SUBTYPE_DATA_SUCCESS: {
      return {
        ...localState,
        isGetPumpSubTypeDataPending: false,
      };
    }
    case SET_GRAPH_DATA: {
      return {
        ...localState,
        graphData: action.payload,
      };
    }
    case GET_GRAPH_DATA_PENDING: {
      return {
        ...localState,
        isGetGraphPending: true,
      };
    }
    case GET_GRAPH_DATA_SUCCESS: {
      return {
        ...localState,
        isGetGraphPending: false,
      };
    }
    case GET_TEST_DATA_PENDING: {
      return {
        ...localState,
        isGetTestDataPending: true,
      };
    }
    case GET_TEST_DATA_SUCCESS: {
      return {
        ...localState,
        isGetTestDataPending: false,
      };
    }
    case GET_NPSH_DATA_PENDING: {
      return {
        ...localState,
        isGetNPSHDataPending: true,
      };
    }
    case GET_NPSH_DATA_SUCCESS: {
      return {
        ...localState,
        isGetNPSHDataPending: false,
      };
    }
    case SET_FILTERED_PUMP_DATA_IDS: {
      return {
        ...localState,
        filteredPumpDataIds: action.payload,
      };
    }
    case ADD_FILTERED_PUMP_DATA_IDS: {
      let { filteredPumpDataIds } = { ...localState };
      filteredPumpDataIds.push(action.payload);
      return {
        ...localState,
        filteredPumpDataIds: filteredPumpDataIds,
      };
    }
    case SET_ACTIVE_PUMP_FAMILY: {
      return {
        ...localState,
        activePumpFamily: action.payload,
      };
    }
    case SET_ACTIVE_PUMP_SUBTYPE: {
      return {
        ...localState,
        activePumpSubType: action.payload,
      };
    }
    case SET_ACTIVE_PUMP_SUBTYPE_DATA: {
      return {
        ...localState,
        activePumpSubTypeData: action.payload,
        activePumpSubTypeDataId: action.payload.id,
      };
    }
    case CLEAR_ACTIVE_PUMP_HI2_SELECTION: {
      return {
        ...localState,
        activePumpSubTypeData: null,
        activePumpSubTypeDataId: null,
        activePumpSubType: null,
        activePumpFamily: null,
      };
    }
    case SET_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...action.payload,
        },
      };
    }
    case CLEAR_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...initialState.filter,
        },
      };
    }
    case SET_SELECTED_PUMP_DATA_DETAILS: {
      const { position, idx } = action.payload;
      return {
        ...localState,
        selectedPumpDataScrollPosition: position,
        selectedPumpDataRowId: idx,
        isDataModified: false,
      };
    }
    case CLEAR_SELECTED_PUMP_DATA_DETAILS: {
      return {
        ...localState,
        selectedPumpDataScrollPosition: 0,
        selectedPumpDataRowId: undefined,
        isDataModified: false,
      };
    }
    case SET_EXPORT_INITIAL_STATE: {
      return {
        ...initialState,
        isExportSelectionModalOpen: false,
      };
    }
    case OPEN_EXPORT_SELECTION_MODAL: {
      return {
        ...localState,
        isExportSelectionModalOpen: true,
      };
    }
    case CLOSE_EXPORT_SELECTION_MODAL: {
      return {
        ...localState,
        isExportSelectionModalOpen: false,
      };
    }
    case SET_PUMP_DATA_LIST_PAGER: {
      return {
        ...localState,
        pager: {
          ...action.payload,
        },
      };
    }
    case CLEAR_PUMP_DETAILS: {
      return {
        ...localState,
        // generalData: {},
        graphData: {},
        inputTestData: [],
        outputTestData: [],
        inputNPSHData: [],
        outputNPSHData: [],
      };
    }
    case RESET_CAN_OPEN_PUMP_DETAILS_FLAG: {
      return {
        ...localState,
        generalData: {
          ...localState.generalData,
          canOpenPumpDetails: true,
        },
      };
    }
    case SET_PUMP_DATA_COPIED_FLAG: {
      return {
        ...localState,
        isPumpDataCopied: true,
      }
    }
    case RESET_PUMP_DATA_COPIED_FLAG: {
      return {
        ...localState,
        isPumpDataCopied: false,
      }
    }
    case SET_2ND_TEST_CURVE: {
      const { set2ndTestCurveId, set2ndTestCurveTestNo, set2ndTestCurvePumpName } = action.payload;
      return {
        ...localState,
        set2ndTestCurveId: set2ndTestCurveId,
        set2ndTestCurveTestNo: set2ndTestCurveTestNo,
        set2ndTestCurvePumpName: set2ndTestCurvePumpName,
      };
    }
    case SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG: {
      return {
        ...localState,
        isPumpDataSaveAndGetPending: true,
      }
    }
    case RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG: {
      return {
        ...localState,
        isPumpDataSaveAndGetPending: false,
      }
    }
    default: {
      return localState;
    }
  }
};
