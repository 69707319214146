import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Router } from "react-router-dom";
import ReactGA from 'react-ga';

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import BasePage from "./components/Layout/BasePage";
import { history } from "./helpers/history";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

console.log(window.__ENV.GAKey);
ReactGA.initialize(window.__ENV.GAKey, {
  debug: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
    cookieDomain: 'none'
  }
});
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Login = lazy(() => import("./components/Pages/Login"));
const Recover = lazy(() => import("./components/Pages/Recover"));
const Lock = lazy(() => import("./components/Pages/Lock"));
const NotFound = lazy(() => import("./components/Pages/NotFound"));
const Error500 = lazy(() => import("./components/Pages/Error500"));
const Maintenance = lazy(() => import("./components/Pages/Maintenance"));
const Home = lazy(() => import("./components/Home/Home"));
const CompleteRegistration = lazy(() =>
  import("./components/Pages/CompleteRegistration")
);
const ResetPassword = lazy(() => import("./components/Pages/ResetPassword"));

const Routes = ({ location }) => {
  return (
    // Page Layout component wrapper
    <BasePage>
      <Suspense fallback={<PageLoader />}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={waitFor(Login)} />
            <Route exact path="/" component={waitFor(Home)} />
            <Route path="/pump-data" component={waitFor(Home)} />
            <Route path="/users" component={waitFor(Home)} />
            <Route path="/profile" component={waitFor(Home)} />
            <Route path="/flow-meters" component={waitFor(Home)} />
            <Route path="/import" component={waitFor(Home)} />
            <Route path="/recover" component={waitFor(Recover)} />
            <Route
              path="/enableuseraccount/:code"
              component={waitFor(CompleteRegistration)}
            />
            <Route
              path="/resetpassword/:code"
              component={waitFor(ResetPassword)}
            />
            <Route path="/lock" component={waitFor(Lock)} />
            <Route path="/notfound" component={waitFor(NotFound)} />
            <Route path="/error500" component={waitFor(Error500)} />
            <Route path="/maintenance" component={waitFor(Maintenance)} />
          </Switch>
        </Router>
      </Suspense>
    </BasePage>
  );
};

export default withRouter(Routes);
